<template>
  <!-- 开放实验 -->
  <div class="openexperiment">
    <div class="contain">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
export default {
  name: "Openexperiment",
  components: {},
  data() {
    return {};
  },
  methods: {},
};
</script>
<style scoped lang="scss">
.openexperiment {
  background: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  .contain {
    width: 1760px;
  }
}
</style>
